@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Anek+Bangla:wght@400;500;600&family=Archivo+Black&family=Montserrat:ital@1&display=swap');

@import url('./css/root.css');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Anek Bangla', sans-serif;
  font-weight: 500;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1d1e1f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
  margin-top: 0;
  margin-bottom: 0;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

img {
  display: block;
}

button {
  cursor: pointer;
  background-color: inherit;
}
