:root {
  --white: white;

  --orange-primary: #f47458;
  --orange-secondary: #e7593a;
  --blue-background-Secondary: #dfebf9;
  --border-white-blue: #86b1f2;
  --text-blue: #0052d6;
  --error-text-color: #f03810;

  --background-white: #fffbed;
  --background-card-white: #fff2e7;
  --background-card-white-blue: #cbe4fd;
  --background-card-dark-blue: #b4d1f1;

  --background-color-white: #fffefb;

  --grey: #707070;
  --black: #1d1e1f;
  --passed: #fff2ef;
}
